<template>
  <div>
    <Modal
      v-model="setWorkerModal"
      width="800"
    >
      <div
        slot="header"
        class="text-center"
      ><span class="p-r-5">{{ info.stationName }}（{{ info.actionDate }}）</span>配置带班人员</div>
      <div>
        <Table
          stripe
          max-height="620"
          size="small"
          :data="list"
          :columns="tableColumns"
          :loading="tableLoading"
        ></Table>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getUserToStationFarList, setWorkdayUser } from '@/api/msp/workdaySchedule'
export default {
  props: {
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      setWorkerModal: false,
      info: {},
      list: [],
      tableLoading: false,
      tableColumns: [
        { title: '人员姓名', align: 'center', key: 'userName' },
        { title: '距离（km）', align: 'center', key: 'distance' },
        { title: '工作时段', align: 'center', key: 'workTimeTypeName' },
        { title: '类型', align: 'center', key: 'staffTypeName' },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            return h('a', {
              on: {
                click: () => {
                  this.onConfirmSet(row)
                }
              }
            }, '确认分配')
          }
        }
      ]
    }
  },
  methods: {
    showModal (data) {
      if (!data) {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
        return false
      }
      this.info = data
      this.getWorkerData()
      this.setWorkerModal = true
    },
    async getWorkerData () {
      this.tableLoading = true
      const postData = {
        actionDate: this.info.actionDate,
        stationId: this.info.stationId
      }
      const result = await getUserToStationFarList(postData).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.list = result
      }
    },
    async onConfirmSet (obj) {
      const postData = {
        userId: obj.userId,
        workdayId: this.info.id
      }
      const result = await setWorkdayUser(postData)
      if (result && result.success) {
        this.$Notice.success({ desc: '操作成功' })
        this.setWorkerModal = false
        this.onSuccess()
      }
    }
  }
}
</script>
