import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 作业令拆解
 * @param {Object} data
 * @returns
 */
export function dismantleWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/dismantleWorkday', qs.stringify(data))
}

/**
 * 分页查询施工计划表的任务明细
 * @param {Object} data
 * @returns
 */
export function getTableTaskPage (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getTableTaskPage', qs.stringify(data))
}

/**
 * 施工计划按位置维度汇总
 * @param {Object} data
 * @returns
 */
export function getTaskitemPositionGather (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getTaskitemPositionGather', qs.stringify(data))
}

/**
 * 分页查询作业令排期列表
 * @param {Object} data
 * @returns
 */
export function getWorkdayPage (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getWorkdayPage', qs.stringify(data))
}

/**
 * 分页查询施工计划任务明细
 * @param {Object} data
 * @returns
 */
export function getWorkdayTaskPage (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getWorkdayTaskPage', qs.stringify(data))
}

/**
 * 设置代班人员
 * @param {Object} data
 * @returns
 */
export function setWorkdayUser (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/setWorkdayUser', qs.stringify(data))
}

/**
 * 施工计划工作时段、施工人数及备注等信息更新
 * @param {Object} data
 * @returns
 */
export function updateWorkday (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/updateWorkday', qs.stringify(data))
}

/**
 * 编辑作业日期
 * @param {Object} data
 * @returns
 */
export function modifyActionDate (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/modifyActionDate', qs.stringify(data))
}

/**
 * 计算代班人员与站点的距离
 * @param {Object} data
 * @returns
 */
export function getUserToStationFarList (data) {
  return request.post('/ooh-msp/v1/workdaySchedule/getUserToStationFarList', qs.stringify(data))
}
